<template>
    <div class="gradient-bg"/>
</template>

<style lang="scss" scoped>
.gradient-bg {
    position: absolute;
    z-index: -1;
    width: clamp(200px, 50vmax, 80vw);
    max-width: 100%;
    inset: 0;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    background-image: linear-gradient(180deg, $primary, $secondary);
    filter: blur(150px);
    border-radius: 8888px;
    pointer-events: none;
}
</style>