<template>
    <div class="container blocks">
        <div class="blocks">
            <loader v-if="pending"/>
            <partials-experience-block
                v-for="(block, index) in experiences"
                v-else-if="experiences.length > 0"
                :index="index"
                v-bind="{...block, ...block.expFields}"
            />
            <notice v-else message="Er zijn geen ervaringen gevonden!" type="error"/>
        </div>
        <slot/>
    </div>
</template>

<script setup>
import {useExperienceStore} from "~/store/experiences";

const expStore = useExperienceStore();
const {pending} = useAsyncData(() => expStore.fetchExperiences(), {immediate: true})
const experiences = computed(() => expStore?.experiences);

console.log(experiences);

</script>

<style lang="scss" scoped>
.blocks {
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: $gutter;
    margin: calc($gutter / 2) 0;
}
</style>
