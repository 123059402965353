<template>
    <div class="container">
        <div class="column column-intro">
            <span class="column-prefix" v-text="prefix"/>
            <span class="column-desc" v-text="paragraph"/>
        </div>
        <div v-for="service in services" class="column column-data">
            <component :is="getIcon(service.icon)" v-if="service.icon" class="column-image"/>
            <span class="column-title" v-text="service.title"/>
            <span v-if="service.desc" class="column-desc" v-text="service.desc"/>
            <Button
                v-if="service.button"
                :href="service.button?.url"
                :target="service.button?.target"
                class="column-button">
                {{ service.button?.title || 'Klik hier' }}
            </Button>
        </div>

        <div class="disclaimer" v-text="disclaimer || '* uhhh...'"/>

        <slot/>
    </div>

</template>

<script setup>
const getIcon = (id) => defineAsyncComponent(() => import(`../../images/icons/${id}.svg`));
defineProps({
    prefix: String,
    paragraph: String,
    services: Array,
    disclaimer: String,
})
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    z-index: 5;
    display: flex;
    flex-flow: row wrap;
    gap: calc($gutter * 2);
}

.disclaimer {
    width: 100%;
    font-size: em(14px);
    text-align: center;
    color: $txt-white-darker;

    @media screen and (max-width: $xs-width) {
        text-align: left;
    }
}


.column {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: flex-start;
    justify-content: center;
    gap: calc($gutter / 3);
    flex: 0 calc(100% / 3 - ($gutter * 1.5));
    $headerHeight: 40px;

    @media screen and (max-width: $sm-width) {
        flex: 0 calc(50% - ($gutter * 1.5));
    }

    @media screen and (max-width: $xs-width) {
        flex: 1 calc(100%);
    }

    &-intro {
        padding-right: calc($gutter * 2);

        @media screen and (max-width: $sm-width) {
            flex: 0 100%;
        }
    }

    &-data {
        justify-content: flex-start;
    }


    &-prefix {
        display: inline-block;
        width: 100%;
        color: white;
        letter-spacing: 0.2px;
        font-weight: 500;
        font-size: em(30px);
        //line-height: em(32px);
        min-height: $headerHeight;
    }

    &-paragraph {
        width: 100%;
    }

    &-title {
        font-weight: 500;
        letter-spacing: 0.2px;
        display: inline-flex;
        align-items: center;
        width: auto;
        flex: 1 auto;
        font-size: em(24px);
        min-height: $headerHeight;
        word-break: break-all;
        word-wrap: break-word;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &-desc {
        color: $txt-white-darker;
        display: inline-block;
        width: 100%;
        text-wrap: balance;
    }

    &-image {
        flex: 0 auto;
        @include fixed-ar($headerHeight);
        color: $txt-white;

        + .column-title {
            margin-left: calc($gutter / 4);
        }
    }


}
</style>