<template>
    <div class="container">
        <div class="ripple">
            <div class="ripple-el ripple-el-1"/>
            <div class="ripple-el ripple-el-2"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: center;
}

.ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    .ripple-el {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

        &-2 {
            animation-delay: -0.5s;
        }
    }
}

@keyframes ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
</style>
<script lang="ts" setup>
</script>