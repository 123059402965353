<template>
    <div v-for="button in buttons" :class="{image: button?.showImage}" class="button-wrapper">
        <Button
            v-if="button?.link"
            :download="button.download"
            :href="button.link.url"
            :primary="button.buttonStyle === 'primary'"
            :secondary="button.buttonStyle === 'secondary'"
            :target="button.link.target"
        >
            <component :is="getIcon(button.icon)" v-if="button.icon"/>
            {{ button.link.title }}
        </Button>
        <slot v-else :button="button" name="button">Iets anders?</slot>
    </div>
</template>

<script setup>
const getIcon = (id) => defineAsyncComponent(() => import(`../../images/icons/${id}.svg`));

defineProps({
    buttons: Array,
})
</script>