import {defineStore} from 'pinia'

export const useExperienceStore = defineStore('experience-store', {
    state: () => ({
        experiences: []
    }),
    actions: {
        async fetchExperiences() {
            const query = gql`query exp {
                allExperiences(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                    nodes {
                        id
                        title
                        expFields {
                            desc
                            fieldGroupName
                            imagefit
                            prefix
                            image {
                                altText
                                id
                                mediaItemUrl
                                mediaItemId
                                mediaType
                                mimeType
                            }
                        }
                        date
                        experienceId
                        status
                        guid
                        dateGmt
                        enclosure
                        databaseId
                        slug
                    }
                }
            }`

            const variables = {limit: 5}
            const {data: result} = await useAsyncQuery(query, variables);
            return this.experiences = result.value?.allExperiences?.nodes;
        }
    },
})