import {defineStore} from 'pinia'

export const usePageStore = defineStore('page-store', {
    state: () => ({
        pages: [],
    }),
    actions: {
        async fetchPages() {
            const query = gql`query pages {
                pages {
                    nodes {
                        id
                        date
                        isFrontPage
                        pageFields {
                            layouts {
                                ... on Page_Pagefields_Layouts_Experiences {
                                    fieldGroupName
                                    spaceAbove
                                    spaceUnder
                                    enableBg
                                    id
                                }
                                ... on Page_Pagefields_Layouts_Intro {
                                    fieldGroupName
                                    prefix
                                    suffix
                                    title
                                    withLines
                                    spaceAbove
                                    spaceUnder
                                    id
                                }
                                ... on Page_Pagefields_Layouts_Text {
                                    content
                                    fieldGroupName
                                    largeContent
                                    prefix
                                    buttons {
                                        fieldGroupName
                                        link {
                                            target
                                            title
                                            url
                                        }
                                        download
                                        buttonStyle
                                    }
                                    spaceAbove
                                    spaceUnder
                                    id
                                }
                                ... on Page_Pagefields_Layouts_ServiceStack {
                                    fieldGroupName
                                    paragraph
                                    prefix
                                    services {
                                        desc
                                        fieldGroupName
                                        icon
                                        title
                                        button {
                                            target
                                            title
                                            url
                                        }
                                    }
                                    spaceAbove
                                    spaceUnder
                                    enableBg
                                    disclaimer
                                    id
                                }
                                ... on Page_Pagefields_Layouts_Contact {
                                    id
                                    fieldGroupName
                                    title
                                    spaceAbove
                                    spaceUnder
                                    socials {
                                        buttonStyle
                                        fieldGroupName
                                        icon
                                        showImage
                                        link {
                                            target
                                            title
                                            url
                                        }
                                        image {
                                            altText
                                            id
                                            mediaItemUrl
                                            mediaItemId
                                            mediaType
                                            mimeType
                                        }
                                    }
                                }
                            }
                            fieldGroupName
                        }
                    }
                }
            }`

            const variables = {limit: 10}
            const {data: result, error} = await useAsyncQuery(query, variables)
            const pages = result.value?.pages?.nodes;

            console.log(result)
            console.error(error.value)
            return this.pages = pages;
        },
    },
    getters: {
        getFrontPage: (state) => {
            console.log((state.pages || []).find(p => p.isFrontPage))
            return (state.pages || []).find(p => p.isFrontPage);
        }
    }
})