<template>
    <div class="layouts">
        <div
            v-for="layout in layouts"
            v-if="layouts"
            :class="{[`space-above-${layout.spaceAbove}`]: layout.spaceAbove, [`space-under-${layout.spaceUnder}`]: layout.spaceUnder}"
            class="layout"
        >
            <page-intro
                v-if="layout.__typename.includes('Layouts_Intro')"
                v-bind="layout">
                <Lines v-if="layout.withLines"/>
            </page-intro>
            <text-block
                v-else-if="layout.__typename.includes('Layouts_Text')"
                v-bind="layout"
            >
                <partials-buttons
                    v-if="layout.buttons"
                    v-slot="slotProps"
                    :buttons="layout.buttons"/>
            </text-block>
            <page-service-stack
                v-else-if="layout.__typename.includes('Layouts_ServiceStack')"
                v-bind="layout"
            >
                <gradient v-if="layout.enableBg"/>
            </page-service-stack>

            <page-contact
                v-else-if="layout.__typename.includes('Page_Pagefields_Layouts_Contact')"
                v-bind="layout"
            />
            <page-experiences
                v-else-if="layout.__typename.includes('Layouts_Experiences')">
                <gradient v-if="layout.enableBg"/>
            </page-experiences>
            <pre v-else v-text="layout"/>
        </div>
        <notice v-else message="Er zijn geen lay-outs gevonden!" type="error"/>
    </div>
</template>

<script lang="ts" setup>
import {usePageStore} from "~/store/pages";

const pageStore = usePageStore();
const layouts = computed(() => pageStore.getFrontPage?.pageFields?.layouts);

</script>

<style lang="scss" scoped>
.layouts {
    display: flex;
    flex-flow: column nowrap;
    gap: $gutter;

    @media screen and (max-width: $xs-width) {
        gap: calc($gutter * 2);
    }

    .layout {
        margin: 0 auto;
        @for $i from 1 through 4 { // Generate .space-above-1 to .space-above-4
            &.space-above-#{$i} {
                padding-top: calc(#{$gutter} * #{$i});
                @media screen and (max-width: $xs-width) {
                    padding-top: 0;
                }
            }
        }

        @for $i from 1 through 4 { // Generate .space-under-1 to .space-under-4
            &.space-under-#{$i} {
                padding-bottom: calc(#{$gutter} * #{$i});
                @media screen and (max-width: $xs-width) {
                    padding-bottom: 0;
                }
            }
        }
    }
}

</style>