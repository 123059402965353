<template>
    <NuxtLayout name="page">
        <NuxtLoadingIndicator/>

        <loader v-if="pending"/>
        <layouts v-else/>

    </NuxtLayout>
</template>

<script lang="ts" setup>
import {usePageStore} from "~/store/pages";

const pageStore = usePageStore();

useSeoMeta({
    title: 'MB - Portfolio',
    ogTitle: 'Marthijn Beilschmidt | Efficiënte oplossingen voor uw online dromen',
    description: "Fullstack WordPress. Na mijn opleidingen en leerzame stages is mijn ambitie verbreed naar een hobby, m’n werk en m’n passie.",
})

const {pending} = useAsyncData(() => pageStore.fetchPages(), {immediate: true});

</script>

<style lang="scss" scoped>
//.experiences {
//    padding-top: calc($gutter * 2);
//    padding-bottom: calc($gutter * 2);
//
//    @media screen and (max-width: $xs-width) {
//        padding-bottom: $gutter;
//    }
//}
//
//.about {
//    padding-top: calc($gutter * 3);
//    padding-bottom: calc($gutter * 4);
//
//    @media screen and (max-width: $xs-width) {
//        padding-top: $gutter;
//        padding-bottom: calc($gutter / 2);
//    }
//}
//
//.services {
//    padding-top: calc($gutter * 2);
//    padding-bottom: calc($gutter * 2);
//
//    @media screen and (max-width: $xs-width) {
//        padding-top: calc($gutter / 2);
//        padding-bottom: calc($gutter / 2);
//    }
//}
//
//.contact {
//    padding-top: calc($gutter * 4);
//
//    @media screen and (max-width: $xs-width) {
//        padding-top: calc($gutter / 2);
//    }
//}
</style>

